export default ({app, $axios, $route}, inject) => {
    const wdevents = {
        getEventID() {
            return Date.now().toString() + Math.floor(Math.random() * 1000).toString()
        },
        send(name, data = {}) {
            if (!('event_source_url' in data)) {
                data['event_source_url'] = window.location.href || '';
            }
            // if (!('client_user_agent' in data)) {
            //     data['client_user_agent'] = window.navigator.userAgent || '';
            // }
            const form_data = new URLSearchParams();
            form_data.append('event_name', name);
            for (const key in data) {
                form_data.append(key, data[key]);
            }
            $axios.post('/?p=facebook_conversions_api', form_data)
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.error(error);
            });
        }
    }

    app.router.afterEach(() => {
        // wdevents.send('pageview')
    })

    inject('wdevents', wdevents);
}
