import Cookies from 'js-cookie';

export default {
    methods: {
        fbqEvent(track, event, params, eventID = null, counter = 10) {
            setTimeout(() => {
                if (counter <= 0) {
                    return
                }
                counter--;
                try {
                    fbq(track, event, params, eventID);
                } catch (e) {
                    setTimeout(() => {
                        this.fbqEvent();
                    }, 500);
                }
            }, 500);
        },
        LgpdResponse() {
            let lgpd_marketing = Cookies.get('lgpd_load_marketing')
            if (this.$wdconfig.default.google_tag_manager && lgpd_marketing === 'true') {
                return true;
            }
            return false;
        },
        FacebookPixelAddToCart(value, id, name, quantity = 1) {
            const eventID = this.$wdevents.getEventID()
            if (this.LgpdResponse()) {
                this.fbqEvent('track', 'AddToCart', {
                    currency: "BRL",
                    content_type: "product",
                    value: value,
                    contents: {
                        id: id,
                        quantity: quantity,
                        name: name,
                    }
                }, eventID)
            }
            this.$wdevents.send(
                'add_to_cart',
                {
                    event_id: eventID,
                    content_ids: [id],
                    content_type: 'product',
                    content_value: value,
                    content_quantity: quantity,
                    content_name: name
                }
            )
        },
        FacebookViewContent(category, name, type, id, value) {
            const eventID = this.$wdevents.getEventID()
            if (this.LgpdResponse()) {
                this.fbqEvent('track', 'ViewContent', {
                    content_name: name,
                    content_category: category,
                    content_type: type,
                    content_ids: [id],
                    value: value,
                    currency: 'BRL',
                    event_id: eventID,
                });
            }
            this.$wdevents.send(
                'view_content',
                {
                    event_id: eventID,
                    content_name: name,
                    content_category: category,
                    content_type: 'product',
                    content_ids: [id],
                    content_value: value,
                    currency: 'BRL',
                }
            )
        }
    }
}
